import merge from 'lodash.merge';

export const mediaConditionMobile = `(min-width: 376px)`;
export const mediaConditionDesktop = `(min-width: 1024px)`;
export const mediaConditionDesktopLarge = `(min-width: 1400px)`;

export const mobile = interpolation => ({
    [`@media ${mediaConditionMobile}`]: interpolation,
});
export const desktop = interpolation => ({
    [`@media ${mediaConditionDesktop}`]: interpolation,
});
export const desktopLarge = interpolation => ({
    [`@media ${mediaConditionDesktopLarge}`]: interpolation,
});

const breakpointMapping = [
    { breakpoint: 'm', mq: null },
    { breakpoint: 'd', mq: desktop },
    { breakpoint: 'dl', mq: desktopLarge },
];

const addToBreakpoint = (breakpoints, bp, interpolation) =>
    merge({}, breakpoints, { [bp]: interpolation });
const mapPropertyValuesToBreakpoints = (
    breakpoints,
    propertyName,
    propertyBreakpoints
) => {
    for (const bp in propertyBreakpoints) {
        breakpoints = addToBreakpoint(breakpoints, bp, {
            [propertyName]: propertyBreakpoints[bp],
        });
    }
    return breakpoints;
};
const applyMediaQueries = mappedBreakpoints => {
    const out = [];
    for (const breakpoint of breakpointMapping) {
        if (typeof mappedBreakpoints[breakpoint.breakpoint] !== 'undefined') {
            out.push(
                breakpoint.mq
                    ? breakpoint.mq(mappedBreakpoints[breakpoint.breakpoint])
                    : mappedBreakpoints[breakpoint.breakpoint]
            );
        }
    }
    return out;
};
const responsiveProperty = properties => {
    let mappedBreakpoints;
    for (const propertyName in properties) {
        const propertyValues = properties[propertyName];
        if (typeof propertyValues === 'object') {
            mappedBreakpoints = mapPropertyValuesToBreakpoints(
                mappedBreakpoints,
                propertyName,
                propertyValues
            );
        } else {
            mappedBreakpoints = addToBreakpoint(mappedBreakpoints, 'm', {
                [propertyName]: propertyValues,
            });
        }
    }
    return applyMediaQueries(mappedBreakpoints);
};
export const rp = responsiveProperty;

export const isDesktop = () =>
    typeof window !== 'undefined' &&
    window.matchMedia(mediaConditionDesktop).matches;
