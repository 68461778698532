import React from 'react';
import {
    borderBlack,
    footerHeightMobile,
    footerHeightDesktop,
    colors,
} from './styleguide';
import { NavigationButton, navigationTexts } from './navigation';
import { desktop } from './responsive';
import { Clickable } from './clickable';
import { useStore } from './state';

const FooterNavigationButton = ({
    children,
    onClick,
    borderRight,
    isSelected,
}) => (
    <NavigationButton
        css={[
            {
                width: '50%',
                flexGrow: 1,
                color: colors.black,
            },
            borderRight
                ? [
                      borderBlack,
                      {
                          borderRightStyle: 'solid',
                      },
                  ]
                : null,
            isSelected ? { background: colors.grayDark } : null,
        ]}
        isSelected={isSelected}
    >
        <Clickable onClick={onClick}>{children}</Clickable>
    </NavigationButton>
);

export const Footer = () => {
    const state = useStore(state => state);
    return (
        <footer
            css={[
                borderBlack,
                {
                    display: 'flex',
                    width: '100%',
                    height: footerHeightMobile,
                    position: 'fixed',
                    bottom: 0,
                    background: colors.white,
                    borderTopStyle: 'solid',
                },
                desktop({
                    height: footerHeightDesktop,
                    borderTopStyle: 'solid',
                }),
            ]}
        >
            <FooterNavigationButton
                onClick={() =>
                    state.menuReadExpanded
                        ? state.closeMenuRead()
                        : state.expandMenuRead()
                }
                isSelected={state.menuReadExpanded}
                borderRight
            >
                {navigationTexts.read}
            </FooterNavigationButton>
            <FooterNavigationButton
                onClick={() =>
                    state.menuLookExpanded
                        ? state.closeMenuLook()
                        : state.expandMenuLook()
                }
                isSelected={state.menuLookExpanded}
            >
                {navigationTexts.look}
            </FooterNavigationButton>
        </footer>
    );
};
