import { create } from 'zustand';
import { isDesktop } from './responsive';
import { navigate } from 'gatsby';

export const isReadPath = pathname => pathname.match(/^\/read/) !== null;
export const isLookPath = pathname => pathname.match(/^\/look/) !== null;
export const isInfoPath = pathname => pathname.match(/^\/info/) !== null;

export const getInitialState = ssrState => {
    const state = {
        menuReadExpanded: false,
        menuLookExpanded: false,
        detailReadExpanded: false,
        detailLookExpanded: false,
        infoExpanded: false,
    };
    if (ssrState && ssrState.pathname && isReadPath(ssrState.pathname)) {
        state.menuReadExpanded = true;
        state.detailReadExpanded = true;
    } else if (ssrState && ssrState.pathname && isLookPath(ssrState.pathname)) {
        state.menuLookExpanded = true;
        state.detailLookExpanded = true;
    } else if (ssrState && ssrState.pathname && isInfoPath(ssrState.pathname)) {
        state.infoExpanded = true;
    }
    // console.log('initial state', state);
    return state;
};

const isDetailExpanded = state =>
    state.detailReadExpanded || state.detailLookExpanded || state.infoExpanded;

/**
 * initial state is read from window.__INITIAL_STATE (undefined for ssr) or window.location
 * on ssr __INITAL_STATE is populated with the page being rendered
 */
export const [useStore, api] = create(set => ({
    ...getInitialState(
        typeof window !== 'undefined'
            ? window.__INITIAL_STATE || window.location
            : {}
    ),
    expandMenuRead: () =>
        set(state => {
            if (isDetailExpanded(state)) {
                navigate('/', { state: { menuReadExpanded: true } }); // options passed to onRouteUpdate to keep menu state
            }
            return {
                menuReadExpanded: true,
                menuLookExpanded: false,
            };
        }),
    expandMenuLook: () =>
        set(state => {
            if (isDetailExpanded(state)) {
                navigate('/', { state: { menuLookExpanded: true } }); // options passed to onRouteUpdate to keep menu state
            }
            return {
                menuLookExpanded: true,
                menuReadExpanded: false,
            };
        }),
    closeMenu: () =>
        set({
            menuReadExpanded: false,
            menuLookExpanded: false,
        }),
    closeMenuRead: () =>
        set(state =>
            isDesktop() && state.detailReadExpanded
                ? {}
                : { menuReadExpanded: false }
        ),
    closeMenuLook: () =>
        set(state =>
            isDesktop() && state.detailLookExpanded
                ? {}
                : { menuLookExpanded: false }
        ),
}));

// const unsub1 = api.subscribe(state => console.log('state changed', state));
