import { rp, desktop } from './responsive';
import {
    fontEuclidTriangleMedium,
    fontEuclidTriangleLight,
    fontEuclidTriangleLightItalic,
} from './fonts';

export const colors = {
    white: `#fff`,
    black: '#000',
    grayDark: '#4d4d4d',
};

export const baseFontSize = {
    mobileSmall: 16,
    mobile: 18,
    desktopLarge: 22,
};
export const rem = px => `${px / baseFontSize.mobile}rem`;

export const borderWidthMobile = 4;
export const borderWidthDesktop = 5;

export const headerHeightMobile = rem(88);
export const headerHeightDesktop = rem(64);

export const footerHeightMobile = rem(43);
export const footerHeightDesktop = headerHeightDesktop;

export const videoAspectRatio = (9 / 16) * 100;

export const uppercase = {
    textTransform: 'uppercase',
};

export const borderBlack = rp({
    borderColor: colors.black,
    borderStyle: 'none',
    borderWidth: { m: `${borderWidthMobile}px`, d: `${borderWidthDesktop}px` },
});

export const centerVertically = {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
};

export const centerHorizontally = {
    alignItems: 'center',
};

const typoNavigation = rp({
    fontSize: { m: '1.875rem', d: '2.625rem' },
    letterSpacing: rem(2.85),
});
const typoContentText = {
    fontSize: '1rem',
    lineHeight: 1.4,
    letterSpacing: rem(0.41),
};

export const typo = {
    navigation: [fontEuclidTriangleMedium, uppercase, typoNavigation],
    contentTitle: [fontEuclidTriangleMedium, uppercase, typoNavigation], // read/look title, mobile only
    contentSubtitle: [fontEuclidTriangleMedium, typoContentText],
    contentText: [fontEuclidTriangleLight, typoContentText],
    contentTextItalic: [fontEuclidTriangleLightItalic, typoContentText],
    contentTextBold: [fontEuclidTriangleMedium, typoContentText],
    contentInfoAbout: [
        fontEuclidTriangleMedium,
        {
            fontSize: rem(22),
            lineHeight: 1.4,
            letterSpacing: rem(0.2),
        },
    ],
};

export const spacing = {
    contentTextSpacingMobile: rem(15),
    contentTextSpacingDesktop: rem(20),
};

export const zIndex = {
    header: 10,
    menu: 10,
    overlay: 20,
};

export const disableSelection = {
    userSelect: 'none',
    WebkitTapHighlightColor: `rgba(0,0,0,0)`,
};

export const textContentSpacing = [
    {
        padding: `0 ${spacing.contentTextSpacingMobile}`,
    },
    desktop({
        padding: `0 ${spacing.contentTextSpacingDesktop}`,
    }),
];
