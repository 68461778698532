import React, { useState } from 'react';
import {
    headerHeightDesktop,
    footerHeightDesktop,
    disableSelection,
} from './styleguide';
import styled from '@emotion/styled';
import { useSprings, animated } from 'react-spring';
import { Clickable } from './clickable';
import range from 'lodash.range';
import { desktop } from './responsive';
import { IconLeft, IconRight } from './icons';

const rotate = (arr, count) => {
    count -= arr.length * Math.floor(count / arr.length);
    arr.push.apply(arr, arr.splice(0, count));
    return arr;
};
const getCenterIndex = len => Math.floor((len - 1) / 2);
const getMappedPositions = len => range(len).map(v => v - getCenterIndex(len));
const getPositions = (len, currentIndex) =>
    rotate(getMappedPositions(len), getCenterIndex(len) - currentIndex);
const isVisible = (positions, i) =>
    (positions[i] >= 0 && positions[i] <= 2) ||
    (positions[i] < 0 && positions[i] >= -1);

const AnimatedSlideContainer = styled(animated.div)({
    position: 'absolute',
    width: '50%',
    height: '100%',
});

const NavigationArea = ({ type, onClick, children }) => {
    const [hover, setHover] = useState(false);

    return (
        <Clickable
            element="div"
            css={[
                disableSelection,
                {
                    position: 'absolute',
                    width: '10%',
                    height: '100%',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                },
                type === 'left' ? { left: 0 } : { right: 0 },
            ]}
            onClick={onClick}
            onMouseOver={() => setHover(true)}
            onMouseOut={() => setHover(false)}
        >
            <div
                css={{
                    opacity: hover ? 1 : 0,
                    transition: `opacity 150ms ease-in`,
                }}
            >
                {children}
            </div>
        </Clickable>
    );
};

export const Slideshow = ({ children }) => {
    const [currentIndex, setCurrentIndex] = useState(0);
    const slides = [
        ...React.Children.toArray(children),
        ...React.Children.toArray(children),
    ];
    const numSlides = slides.length;

    const [springs, set] = useSprings(numSlides, i => {
        const positions = getPositions(numSlides, currentIndex);
        return {
            from: {
                x: positions[i],
                display: isVisible(positions, i) ? 'block' : 'none',
            },
        };
    });

    set(i => {
        const positions = getPositions(numSlides, currentIndex);
        return {
            x: positions[i],
            display: isVisible(positions, i) ? 'block' : 'none',
        };
    });

    return (
        <div
            css={[
                {
                    display: 'none',
                    overflow: 'hidden',
                    position: 'fixed',
                    top: headerHeightDesktop,
                    bottom: footerHeightDesktop,
                    width: '100%',
                },
                desktop({ display: 'block' }),
            ]}
        >
            {springs.map(({ x, display }, i) => (
                <AnimatedSlideContainer
                    key={i}
                    style={{
                        display,
                        transform: x.interpolate(
                            x => `translate3d(${x * 100}%,0,0)`
                        ),
                    }}
                >
                    {slides[i]}
                </AnimatedSlideContainer>
            ))}
            <NavigationArea
                type="left"
                onClick={() =>
                    setCurrentIndex(i => (i === 0 ? numSlides - 1 : i - 1))
                }
            >
                <IconLeft />
            </NavigationArea>
            <NavigationArea
                type="right"
                onClick={() =>
                    setCurrentIndex(i => (i === numSlides - 1 ? 0 : i + 1))
                }
            >
                <IconRight />
            </NavigationArea>
        </div>
    );
};
