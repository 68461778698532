// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-templates-info-page-template-js": () => import("/home/runner/work/swisscraftrevisited/swisscraftrevisited/src/templates/infoPageTemplate.js" /* webpackChunkName: "component---src-templates-info-page-template-js" */),
  "component---src-templates-read-page-template-js": () => import("/home/runner/work/swisscraftrevisited/swisscraftrevisited/src/templates/readPageTemplate.js" /* webpackChunkName: "component---src-templates-read-page-template-js" */),
  "component---src-templates-look-page-template-js": () => import("/home/runner/work/swisscraftrevisited/swisscraftrevisited/src/templates/lookPageTemplate.js" /* webpackChunkName: "component---src-templates-look-page-template-js" */),
  "component---src-templates-project-page-template-js": () => import("/home/runner/work/swisscraftrevisited/swisscraftrevisited/src/templates/projectPageTemplate.js" /* webpackChunkName: "component---src-templates-project-page-template-js" */),
  "component---src-pages-404-js": () => import("/home/runner/work/swisscraftrevisited/swisscraftrevisited/src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-js": () => import("/home/runner/work/swisscraftrevisited/swisscraftrevisited/src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */)
}

