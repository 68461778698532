import React from 'react';
import styled from '@emotion/styled';

export const IconClose = () => (
    <svg
        version="1.1"
        id="prefix__Ebene_1"
        xmlns="http://www.w3.org/2000/svg"
        x="0"
        y="0"
        width="27"
        height="27"
        viewBox="0 0 141.73 141.73"
    >
        <path
            d="M11.33 130.4L130.4 11.33M130.4 130.4L11.33 11.33"
            stroke="#fff"
            strokeWidth="8"
        />
    </svg>
);

export const IconPlay = props => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        version="1.1"
        x="0"
        y="0"
        width="34.641"
        height="40"
        {...props}
    >
        <path d="M0 0v40l34.64-19.998z" id="path817" />
    </svg>
);

const Icon = styled.img();

export const IconLeft = props => (
    <Icon
        data-props={JSON.stringify(props)}
        css={[{ width: 70 }]}
        src={require('../assets/icons/Icon_Left.svg')}
        {...props}
    />
);

export const IconRight = () => (
    <Icon css={{ width: 70 }} src={require('../assets/icons/Icon_Right.svg')} />
);
