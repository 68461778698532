import React from 'react';
import styled from '@emotion/styled';
import {
    typo,
    centerHorizontally,
    centerVertically,
    colors,
    disableSelection,
} from './styleguide';
import { Link } from 'gatsby';
import css from '@emotion/css';

// used in footer and header as a button
export const NavigationButton = styled.a(
    css([
        disableSelection,
        typo.navigation,
        centerHorizontally,
        centerVertically,
        { textDecoration: 'none' },
    ])
);

// used in menu item and header home links
export const StyledLink = ({ children, inverted, ...props }) => (
    <Link
        css={[
            disableSelection,
            {
                textDecoration: `none`,
                color: inverted ? colors.white : colors.black,
            },
        ]}
        {...props}
    >
        {children}
    </Link>
);

export const navigationTexts = {
    read: `Read`,
    look: 'Look',
};
