import React, { useEffect } from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import styled from '@emotion/styled';
import {
    colors,
    borderBlack,
    borderWidthDesktop,
    headerHeightMobile,
    headerHeightDesktop,
    typo,
    footerHeightMobile,
    footerHeightDesktop,
    rem,
    disableSelection,
} from './styleguide';
import { desktop } from './responsive';
import { StyledLink } from './navigation';
import { useStore } from './state';
import { scrollContainer } from './scrollcontainer';
import { Location } from '@reach/router';
import css from '@emotion/css';

const menuContainer = css([
    borderBlack,
    scrollContainer,
    {
        position: 'fixed',
        width: '100%',
        overflowX: 'hidden',
        overflowY: 'auto',
        bottom: footerHeightMobile,
        top: headerHeightMobile,
        background: colors.white,
    },
    desktop({
        bottom: footerHeightDesktop,
        top: headerHeightDesktop,
    }),
]);

const MenuItemText = styled.span([
    disableSelection,
    typo.navigation,
    {
        display: 'block',
        textAlign: 'center',
    },
]);

const MenuItem = ({ title, to }) => (
    <Location>
        {({ location }) => {
            const isActive = location.pathname === to;
            return (
                <li
                    css={{
                        listStyleType: 'none',
                        padding: `${rem(10)} 0`,
                        background: isActive ? colors.grayDark : 'inherit',
                    }}
                >
                    <StyledLink to={to} inverted={isActive}>
                        <MenuItemText>{title}</MenuItemText>
                    </StyledLink>
                </li>
            );
        }}
    </Location>
);

const MenuItems = ({ items, pathPrefix }) => (
    <ul>
        {items.map(({ node: item }, i) => (
            <MenuItem
                key={i}
                to={`${pathPrefix}${item.fields.slug}`}
                title={item.title}
            />
        ))}
    </ul>
);

const CraftsMenu = ({ type, expanded }) => {
    const crafts = useStaticQuery(graphql`
        query Menu {
            allCraftJson(sort: { fields: [displayOrder], order: ASC }) {
                edges {
                    node {
                        title
                        displayOrder
                        fields {
                            slug
                        }
                    }
                }
            }
        }
    `);

    return (
        <nav
            data-expanded={expanded}
            css={[
                menuContainer,
                borderBlack,
                {
                    transitionProperty: 'transform, opacity',
                    transitionDuration: '450ms',
                },
                expanded
                    ? [
                          {
                              transform: 'none',
                              opacity: 1,
                              height: 'auto',
                          },
                      ]
                    : [
                          {
                              transform: `translateY(30%)`,
                              opacity: 0,
                              height: 0,
                              overflow: 'hidden',
                          },
                      ],
                type === 'read'
                    ? [
                          {
                              left: 0,
                          },
                          desktop({
                              borderRightStyle: 'solid',
                          }),
                      ]
                    : [
                          {
                              right: 0,
                          },
                          desktop({
                              borderLeftStyle: 'solid',
                          }),
                      ],
                desktop({
                    width:
                        type === 'read'
                            ? '50%'
                            : `calc(50% + ${borderWidthDesktop}px)`,
                }),
            ]}
        >
            <MenuItems
                items={crafts.allCraftJson.edges}
                pathPrefix={`/${type}`}
            />
        </nav>
    );
};

export const ReadMenu = () => {
    const expanded = useStore(state => state.menuReadExpanded);
    return <CraftsMenu type="read" expanded={expanded} />;
};
export const LookMenu = () => {
    const expanded = useStore(state => state.menuLookExpanded);
    return <CraftsMenu type="look" expanded={expanded} />;
};

export const ScrollLocking = () => {
    const expanded = useStore(
        state => state.menuReadExpanded || state.menuLookExpanded
    );

    useEffect(() => {
        document.body.style.overflowY = expanded ? 'hidden' : 'auto';
    }, [expanded]);

    return null;
};
