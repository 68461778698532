import React from 'react';
import SEO from './seo';
import './normalize.css';
import { FontFaceDefinitions } from './fonts';
import { Global, css } from '@emotion/core';
import { Header } from './header';
import { Footer } from './footer';
import { rp, desktopLarge, mobile } from './responsive';
import {
    baseFontSize,
    headerHeightMobile,
    headerHeightDesktop,
    footerHeightMobile,
    footerHeightDesktop,
    colors,
} from './styleguide';
import { ReadMenu, LookMenu, ScrollLocking } from './menu';
import { api, getInitialState } from './state';
import { VideoPlayerTheme } from './videoPlayer';
import { LandingPageDesktop } from './landingPage';
import { InfoPageLayout } from './infoPage';

export const Layout = ({ children, location }) => {
    // initializes state for ssr with current location to make state consistent with client
    if (typeof window === 'undefined' && location) {
        api.setState(getInitialState(location));
    }

    return (
        <>
            <SEO lang="de" />
            <Global
                styles={[
                    css`
                        html,
                        body {
                            min-height: 100vh;
                        }
                        p,
                        ul,
                        li,
                        ul,
                        h1,
                        h2,
                        h3,
                        h4 {
                            margin: 0;
                            padding: 0;
                        }
                        body {
                            background-color: #fff;
                            color: ${colors.grayDark};
                        }
                        body {
                            scrollbar-width: none;
                        }
                        body::-webkit-scrollbar {
                            display: none;
                        }
                        *:focus {
                            outline: none;
                        }
                    `,
                    { html: { fontSize: baseFontSize.mobileSmall } },
                    mobile({ html: { fontSize: baseFontSize.mobile } }),
                    desktopLarge({
                        html: { fontSize: baseFontSize.desktopLarge },
                    }),
                ]}
            />
            <VideoPlayerTheme />
            <FontFaceDefinitions />
            <div
                css={{
                    display: `flex`,
                    flexDirection: `column`,
                }}
            >
                <Header />
                <main
                    css={rp({
                        paddingTop: {
                            m: headerHeightMobile,
                            d: headerHeightDesktop,
                        },
                        paddingBottom: {
                            m: footerHeightMobile,
                            d: footerHeightDesktop,
                        },
                    })}
                >
                    <LandingPageDesktop />
                    {location &&
                    location.pathname &&
                    location.pathname.indexOf('/info') === 0 ? (
                        <InfoPageLayout location={location}>
                            {children}
                        </InfoPageLayout>
                    ) : (
                        children
                    )}
                </main>
                <ReadMenu />
                <LookMenu />
                <Footer />
                <ScrollLocking />
            </div>
        </>
    );
};
