import React, { useEffect, useRef, useState } from 'react';
import videojs from 'video.js';
import 'video.js/dist/video-js.css';
import { Global, css } from '@emotion/core';
import { fontFamilyEuclidTriangleMedium } from './fonts';
import { Clickable } from './clickable';
import { IconPlay } from './icons';
import { rem } from './styleguide';
import { FluidContainer169 } from './fluidContainer';

export const VideoPlayerTheme = () => (
    <Global
        styles={css`
            .vjs-poster {
                background-color: inherit !important;
                background-size: cover !important;
            }
            .video-js {
                font-family: ${fontFamilyEuclidTriangleMedium} !important;
                background-color: inherit !important;

                .vjs-control-bar,
                .vjs-slider,
                .vjs-load-progress,
                .vjs-load-progress div {
                    background: #000 !important;
                }
                .vjs-progress-control .vjs-progress-holder {
                    height: 1px !important;
                }
                .vjs-time-tooltip {
                    display: none !important;
                }
                .video-js .vjs-play-progress:before {
                }
                .vjs-progress-control:hover .vjs-progress-holder {
                    font-size: 1em !important;
                }
                .vjs-big-play-button {
                    display: none !important;
                }
            }
        `}
    />
);

const setupUI = player => {
    player.controlBar.volumePanel.hide();
    player.controlBar.pictureInPictureToggle.hide();
};

const PlayButton = ({ onClick }) => (
    <Clickable
        element="button"
        onClick={onClick}
        css={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            marginLeft: rem(-17),
            marginTop: rem(-20),
            lineHeight: 0,
            border: 0,
            display: 'block',
            background: 'inherit',
        }}
    >
        <IconPlay />
    </Clickable>
);

export const VideoPlayer = ({ media, autoplay, posterImageSrc }) => {
    const videoElement = useRef();
    const player = useRef();
    const [playButtonVisible, setPlayButtonVisible] = useState(true);

    const hidePlayButton = () => setPlayButtonVisible(false);

    useEffect(() => {
        player.current = videojs(videoElement.current, {
            controls: true,
            bigPlayButton: true,
            sources: media ? media : [],
            ...(posterImageSrc ? { poster: posterImageSrc } : {}),
        });

        setupUI(player.current);
        player.current.on('play', () => hidePlayButton());

        if (autoplay) {
            player.current.ready(() => {
                window.setTimeout(() => {
                    player.current.play();
                }, 0);
            });
        }

        // cleanup callback to dispose player
        return () => player.current && player.current.dispose();
    }, []);

    return (
        <FluidContainer169>
            <div data-vjs-player>
                <video
                    ref={videoElement}
                    disablePictureInPicture
                    className="video-js vjs-fluid"
                    playsInline
                />
            </div>
            {playButtonVisible ? (
                <PlayButton
                    onClick={() => {
                        player.current.play();
                        hidePlayButton();
                    }}
                />
            ) : null}
        </FluidContainer169>
    );
};
