import React from 'react';
import {
    footerHeightMobile,
    footerHeightDesktop,
    headerHeightMobile,
    headerHeightDesktop,
    colors,
    typo,
    rem,
    textContentSpacing,
} from './styleguide';
import { desktop } from './responsive';
import styled from '@emotion/styled';
import { scrollContainer } from './scrollcontainer';
import { Link, useStaticQuery, graphql } from 'gatsby';
import { MarkdownTextAbout, MarkdownTextImprint } from './markdown';
import { Location } from '@reach/router';
import Img from 'gatsby-image';
import { VideoPlayer } from './videoPlayer';
import { keyframes } from '@emotion/core';

const PageContainer = styled.article([
    {
        bottom: footerHeightMobile,
        top: headerHeightMobile,
        width: '100%',
        background: colors.white,
        display: 'flex',
        flexDirection: 'column',
        position: 'fixed',
        overflowY: 'auto',
    },
    desktop([
        {
            flexDirection: 'row',
            bottom: footerHeightDesktop,
            top: headerHeightDesktop,
            overflow: 'hidden',
            width: `100%`,
        },
    ]),
]);

const AboutContainer = styled.section([
    {
        order: 0,
        paddingTop: rem(20),
        marginBottom: rem(32),
    },
    desktop([
        scrollContainer,
        {
            width: `50%`,
            height: '100%',
            overflowY: 'auto',
            paddingTop: '1rem',
        },
    ]),
]);

const ImprintContainer = styled.section([
    { order: 2 },
    desktop([
        scrollContainer,
        {
            order: 1,
            width: `25%`,
            height: '100%',
            overflowY: 'auto',
            paddingTop: '1rem',
        },
    ]),
]);

const ProjectsContainer = styled.div(({ detailVisible }) => [
    {
        background: colors.black,
        order: 1,
    },
    desktop([
        {
            width: `100%`,
            position: `fixed`,
            display: `flex`,
            bottom: footerHeightDesktop,
            top: headerHeightDesktop,
            left: `100%`,
            marginLeft: '30%',
            transition: `transform 350ms ease-in-out`,
            transform: `translateX(${detailVisible ? -100 : -55}%)`,
        },
    ]),
]);

const ProjectListContainer = styled.section(() => [
    {},
    desktop([
        scrollContainer,
        {
            width: `25%`,
            height: '100%',
            overflowY: 'auto',
            paddingTop: '1rem',
        },
    ]),
]);

const ProjectList = styled.ul();

const ProjectListItem = styled.li([
    typo.contentText,
    textContentSpacing,
    { color: colors.white, marginBottom: rem(32) },
]);

const ProjectLink = props => (
    <Location>
        {({ location }) => (
            <Link
                {...props}
                css={[
                    {
                        display: 'block',
                        color: colors.white,
                        textDecoration:
                            location.pathname === props.to
                                ? 'underline'
                                : 'none',
                    },
                ]}
            />
        )}
    </Location>
);

const isDetailVisible = location =>
    location &&
    location.pathname &&
    location.pathname.indexOf(`/info/projekt/`) === 0;

export const InfoPageLayout = ({ children, location }) => {
    const data = useStaticQuery(graphql`
        {
            infoJson {
                id
                about {
                    body
                }
                imprint {
                    title
                    body
                }
            }
            allProjectJson(sort: { fields: [date], order: ASC }) {
                edges {
                    node {
                        title
                        date(formatString: "MM/YYYY")
                        fields {
                            slug
                        }
                    }
                }
            }
        }
    `);

    return (
        <>
            <PageContainer>
                <AboutContainer>
                    <MarkdownTextAbout text={data.infoJson.about.body} />
                </AboutContainer>
                <ImprintContainer>
                    <h2
                        css={[
                            typo.navigation,
                            {
                                textAlign: 'center',
                                marginTop: rem(32),
                                marginBottom: rem(32),
                            },
                            desktop([
                                typo.contentSubtitle,
                                textContentSpacing,
                                {
                                    marginTop: 0,
                                    marginBottom: '1rem',
                                    textAlign: 'left',
                                },
                            ]),
                        ]}
                    >
                        {data.infoJson.imprint.title}
                    </h2>

                    <MarkdownTextImprint text={data.infoJson.imprint.body} />
                </ImprintContainer>
                <ProjectsContainer detailVisible={isDetailVisible(location)}>
                    <ProjectListContainer>
                        <h2
                            css={[
                                typo.navigation,
                                {
                                    color: colors.white,
                                    textAlign: 'center',
                                    marginTop: rem(32),
                                    marginBottom: rem(32),
                                },
                                desktop([
                                    typo.contentSubtitle,
                                    textContentSpacing,
                                    {
                                        marginTop: 0,
                                        marginBottom: '1rem',
                                        textAlign: 'left',
                                    },
                                ]),
                            ]}
                        >
                            Projekte
                        </h2>

                        <ProjectList>
                            {data.allProjectJson.edges.map((p, i) => (
                                <ProjectListItem key={i}>
                                    <ProjectLink
                                        to={`/info/projekt${p.node.fields.slug}`}
                                    >
                                        {p.node.date}
                                        <br />
                                        {p.node.title}
                                    </ProjectLink>
                                </ProjectListItem>
                            ))}
                        </ProjectList>
                    </ProjectListContainer>
                    {children}
                </ProjectsContainer>
            </PageContainer>
        </>
    );
};

const fadeInAnimation = keyframes`
from {
    opacity: 0;
}
to {
    opacity: 1;
}
`;

export const ProjectDetailContainer = styled.section([
    {
        background: colors.black,
        position: 'fixed',
        top: 0,
        bottom: 0,
        width: '100%',
    },
    desktop([
        scrollContainer,
        {
            position: 'static',
            width: `45%`,
            height: '100%',
            overflowY: 'auto',
            paddingTop: '1rem',
            paddingBottom: '2rem',
            animation: `${fadeInAnimation} 650ms ease`,
        },
    ]),
]);

export const ProjectDetailTitle = ({ project }) => (
    <h2
        css={[
            typo.contentSubtitle,
            textContentSpacing,
            { color: colors.white },
        ]}
    >
        {project.date}
        <br />
        {project.title}
    </h2>
);

export const ProjectDetailText = styled.div({
    color: colors.white,
    marginTop: rem(32),
});

export const ProjectDetailVideo = ({ video }) =>
    video && video.stillImage && video.media ? (
        <div css={[textContentSpacing, { marginTop: '1rem' }]}>
            <VideoPlayer
                media={video.media}
                posterImageSrc={video.stillImage.childImageSharp.resize.src}
            />
        </div>
    ) : null;

export const ProjectDetailImage = ({ image }) =>
    image ? (
        <div css={[textContentSpacing, { marginTop: '1rem' }]}>
            <Img fluid={image.childImageSharp.fluid} />
        </div>
    ) : null;
