import styled from '@emotion/styled';
import { videoAspectRatio } from './styleguide';

export const FluidContainer169 = styled.div([
    {
        width: '100%',
        height: 0,
        overflow: 'hidden',
        paddingBottom: `${videoAspectRatio}%`,
        position: 'relative',
    },
]);
