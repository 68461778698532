import React from 'react';

export const Clickable = ({
    element,
    children,
    onClick,
    disabled,
    cursor,
    ...props
}) =>
    React.createElement(
        element ? element : 'div',
        {
            style: {
                cursor: cursor || 'pointer',
            },
            onClick: e => !disabled && onClick && onClick(e),
            ...props,
        },
        children
    );
