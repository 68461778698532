import React from 'react';
import styled from '@emotion/styled';
import {
    typo,
    colors,
    borderBlack,
    centerHorizontally,
    centerVertically,
    headerHeightMobile,
    headerHeightDesktop,
    spacing,
    rem,
    zIndex,
    disableSelection,
} from './styleguide';
import { StyledLink } from './navigation';
import { desktop } from './responsive';
import { useStaticQuery, graphql, Link } from 'gatsby';
import { useStore } from './state';
import css from '@emotion/css';
import { Location } from '@reach/router';

const HeaderMain = styled.h1([
    centerVertically,
    centerHorizontally,
    {
        background: colors.black,
        flexGrow: 1,
        paddingLeft: spacing.contentTextSpacingMobile,
    },
    desktop({
        paddingLeft: 0,
    }),
]);

const HeaderMainText = styled.span([
    typo.navigation,
    {
        color: colors.white,
    },
]);

const InfoLink = () => (
    <Location>
        {({ location }) => (
            <Link
                to="/info"
                css={[
                    disableSelection,
                    typo.navigation,
                    centerHorizontally,
                    centerVertically,
                    {
                        textDecoration: 'none',
                        color: colors.black,
                        width: rem(42),
                        textAlign: 'center',
                        writingMode: 'vertical-lr',
                        letterSpacing: 0,
                        background:
                            location.pathname.indexOf(`/info`) === 0
                                ? colors.grayDark
                                : 'inherit',
                    },
                    desktop({
                        width: '25%',
                        writingMode: 'horizontal-tb',
                    }),
                ]}
            >
                Info
            </Link>
        )}
    </Location>
);

export const Header = () => {
    const data = useStaticQuery(graphql`
        query HeaderQuery {
            site {
                siteMetadata {
                    title
                }
            }
        }
    `);
    const closeMenu = useStore(state => state.closeMenu);
    return (
        <header
            css={[
                disableSelection,
                borderBlack,
                css({
                    display: 'flex',
                    position: 'fixed',
                    top: 0,
                    height: headerHeightMobile,
                    width: '100%',
                    overflow: 'hidden',
                    background: colors.white,
                    borderBottomStyle: 'solid',
                    zIndex: zIndex.header,
                }),
                desktop({
                    height: headerHeightDesktop,
                }),
            ]}
        >
            <HeaderMain>
                <StyledLink to="/" inverted onClick={() => closeMenu()}>
                    <HeaderMainText>
                        {data.site.siteMetadata.title}
                    </HeaderMainText>
                </StyledLink>
            </HeaderMain>
            <InfoLink />
        </header>
    );
};
