import React from 'react';
import { Layout } from './src/components/layout';
import { isDesktop } from './src/components/responsive';
import {
    api,
    isReadPath,
    isLookPath,
    isInfoPath,
} from './src/components/state';

export const wrapPageElement = ({ element, props: { location } }) => {
    return <Layout location={location}>{element}</Layout>;
};

export const onRouteUpdate = ({ location }) => {
    const readOpen = isReadPath(location.pathname);
    const lookOpen = isLookPath(location.pathname);
    const infoOpen = isInfoPath(location.pathname);

    const detailState = {
        detailReadExpanded: readOpen,
        detailLookExpanded: lookOpen,
        infoExpanded: infoOpen,
    };

    const state = isDesktop()
        ? {
              ...detailState,
              menuReadExpanded:
                  readOpen ||
                  (location.state && location.state.menuReadExpanded),
              menuLookExpanded:
                  lookOpen ||
                  (location.state && location.state.menuLookExpanded),
          }
        : {
              ...detailState,
              menuReadExpanded:
                  (location.state && location.state.menuReadExpanded) || false,
              menuLookExpanded:
                  (location.state && location.state.menuLookExpanded) || false,
          };
    api.setState(state);
};
