import React, { useLayoutEffect, useRef } from 'react';
import ReactDOM from 'react-dom';
import styled from '@emotion/styled';
import { colors, typo, zIndex } from './styleguide';
import { Clickable } from './clickable';
import { IconClose } from './icons';
import { desktop } from './responsive';
import { keyframes } from '@emotion/core';

const fadeInAnimation = keyframes`
from {
    opacity: 0.2;
    transform: scale(0.95);
}
to {
    opacity: 1;
    transform: scale(1);
}
`;

const OverlayContainer = styled.div(({ styles }) => [
    {
        zIndex: zIndex.overlay,
        background: colors.black,
        position: 'fixed',
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        animation: `${fadeInAnimation} 350ms ease`,
    },
    desktop({
        padding: '2rem',
        paddingTop: 0,
    }),
    ...(styles || []),
]);

export const OverlayHeader = styled.div([
    {
        display: 'flex',
        flexShrink: 0,
        width: '100%',
        height: '3.5rem',
        alignItems: 'center',
        justifyContent: 'space-between',
        padding: '2rem',
    },
    desktop({
        padding: 0,
    }),
]);

export const OverlayBody = styled.div([
    {
        flex: 1,
        overflow: 'hidden',
        display: 'flex',
        width: '100%',
    },
]);

export const OverlayClose = props => (
    <Clickable
        element="button"
        css={{
            lineHeight: 0,
            border: 0,
            display: 'block',
            color: colors.white,
            background: 'inherit',
        }}
        {...props}
    >
        <IconClose />
    </Clickable>
);

export const OverlayTitle = styled.div([
    typo.contentText,
    { color: colors.white },
]);

export const Overlay = ({ children, containerStyles }) => {
    const el = useRef();
    el.current =
        typeof document !== 'undefined' ? document.createElement('div') : null;

    useLayoutEffect(() => {
        document.body.appendChild(el.current);
        return () => {
            if (document.body.contains(el.current)) {
                document.body.removeChild(el.current);
            }
        };
    });

    return el.current
        ? ReactDOM.createPortal(
              <OverlayContainer styles={containerStyles}>
                  {children}
              </OverlayContainer>,
              el.current
          )
        : null;
};
