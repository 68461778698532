import React, { useState } from 'react';
import styled from '@emotion/styled';
import { graphql, useStaticQuery } from 'gatsby';
import { videoAspectRatio, disableSelection } from '../components/styleguide';
import { desktop, mediaConditionDesktop } from '../components/responsive';
import { Clickable } from '../components/clickable';
import { Overlay, OverlayBody, OverlayHeader, OverlayClose } from './overlay';
import { VideoPlayer } from './videoPlayer';
import { Slideshow } from './slideshow';

const PreviewContainer = ({ children, videoMedia, posterImageSrc }) => {
    const [overlayVisible, setOverlayVisible] = useState(false);

    return (
        <Clickable
            element="div"
            css={[
                disableSelection,
                {
                    width: '100%',
                    height: 0,
                    overflow: 'hidden',
                    paddingTop: `${videoAspectRatio}%`,
                    position: 'relative',
                },
                desktop({
                    height: '100%',
                    paddingTop: 0,
                }),
            ]}
            onClick={() => setOverlayVisible(true)}
        >
            {children}
            {overlayVisible ? (
                <Overlay>
                    <OverlayHeader
                        css={{ maxWidth: '150vh', justifyContent: 'flex-end' }}
                    >
                        <OverlayClose
                            onClick={e => {
                                e.stopPropagation();
                                setOverlayVisible(false);
                            }}
                        />
                    </OverlayHeader>
                    <OverlayBody>
                        <div
                            css={{
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                width: '100%',
                            }}
                        >
                            <div
                                css={{
                                    width: '100%',
                                    maxWidth: '150vh',
                                    position: 'relative',
                                }}
                            >
                                <VideoPlayer
                                    media={videoMedia}
                                    posterImageSrc={posterImageSrc}
                                />
                            </div>
                        </div>
                    </OverlayBody>
                </Overlay>
            ) : null}
        </Clickable>
    );
};

const PreviewImage = ({ srcLandscape, srcPortrait, alt }) => (
    <picture
        css={{
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
        }}
    >
        <source srcSet={srcPortrait} media={mediaConditionDesktop} />
        <img
            alt={alt}
            css={{ width: '100%', height: '100%', objectFit: 'cover' }}
            src={srcLandscape}
        />
    </picture>
);

const getPreviews = () => {
    const data = useStaticQuery(graphql`
        query LandingPage {
            allCraftJson(sort: { fields: [displayOrder], order: ASC }) {
                edges {
                    node {
                        id
                        title
                        videoStill {
                            landscape {
                                childImageSharp {
                                    resize(width: 1024, quality: 90) {
                                        src
                                        width
                                        height
                                        aspectRatio
                                    }
                                }
                            }
                            portrait {
                                childImageSharp {
                                    resize(width: 800, quality: 90) {
                                        src
                                        width
                                        height
                                        aspectRatio
                                    }
                                }
                            }
                        }
                        videoMedia {
                            src
                            type
                        }
                    }
                }
            }
        }
    `);
    return data.allCraftJson.edges.map(({ node }) => (
        <PreviewContainer
            key={node.id}
            videoMedia={node.videoMedia}
            posterImageSrc={
                node.videoStill.landscape.childImageSharp.resize.src
            }
        >
            <PreviewImage
                alt={node.title}
                srcLandscape={
                    node.videoStill.landscape.childImageSharp.resize.src
                }
                srcPortrait={
                    node.videoStill.portrait.childImageSharp.resize.src
                }
            />
        </PreviewContainer>
    ));
};

const PageContainerMobile = styled.section([
    desktop({ display: 'none' }),
    { width: '100%' },
]);

export const LandingPageMobile = () => (
    <PageContainerMobile>{getPreviews()}</PageContainerMobile>
);

export const LandingPageDesktop = () => <Slideshow>{getPreviews()}</Slideshow>;
