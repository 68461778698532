import React from 'react';
import { css, Global } from '@emotion/core';

const fontFamilyEuclidTriangleLight = 'SCR_EuclidTriangleLight';
const fontFamilyEuclidTriangleLightItalic = 'SCR_EuclidTriangleLightItalic';
export const fontFamilyEuclidTriangleMedium = 'SCR_EuclidTriangleMedium';

const defineFont = (family, woff2, woff) => `
@font-face {
    font-family: '${family}';
    src: url('${woff2}') format('woff2'), url('${woff}') format('woff');
    font-weight: normal;
    font-style: normal;
}`;

export const FontFaceDefinitions = () => (
    <Global
        styles={css`
            ${defineFont(
                fontFamilyEuclidTriangleLight,
                require('../assets/fonts/EuclidTriangle-Light-WebS.woff2'),
                require('../assets/fonts/EuclidTriangle-Light-WebS.woff')
            )}
            ${defineFont(
                fontFamilyEuclidTriangleLightItalic,
                require('../assets/fonts/EuclidTriangle-LightItalic-WebS.woff2'),
                require('../assets/fonts/EuclidTriangle-LightItalic-WebS.woff')
            )}
            ${defineFont(
                fontFamilyEuclidTriangleMedium,
                require('../assets/fonts/EuclidTriangle-Medium-WebS.woff2'),
                require('../assets/fonts/EuclidTriangle-Medium-WebS.woff')
            )}
        `}
    />
);

export const fontEuclidTriangleLight = css({
    fontFamily: `'${fontFamilyEuclidTriangleLight}'`,
    fontWeight: `normal`,
    fontStyle: `normal`,
});
export const fontEuclidTriangleLightItalic = css({
    fontFamily: `'${fontFamilyEuclidTriangleLightItalic}'`,
    fontWeight: `normal`,
    fontStyle: `normal`,
});
export const fontEuclidTriangleMedium = css({
    fontFamily: `'${fontFamilyEuclidTriangleMedium}'`,
    fontWeight: `normal`,
    fontStyle: `normal`,
});
