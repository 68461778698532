import React, { useRef } from 'react';
import unified from 'unified';
import markdown from 'remark-parse';
import remark2rehype from 'remark-rehype';
import rehype2react from 'rehype-react';
import externalLinks from 'remark-external-links';
import { typo, rem, colors, textContentSpacing } from './styleguide';

const MDText = ({ text }) => {
    const processor = useRef();
    processor.current = unified()
        .use(markdown, { commonmark: true })
        .use(externalLinks)
        .use(remark2rehype)
        .use(rehype2react, { createElement: React.createElement });

    return processor.current.processSync(text).contents;
};

export const MarkdownText = ({ text }) => {
    return (
        <div
            css={[
                {
                    'h1, h2': [
                        typo.contentSubtitle,
                        textContentSpacing,
                        {
                            marginTop: '0.5rem',
                            marginBottom: '1.3rem',
                        },
                    ],

                    p: [
                        typo.contentText,
                        textContentSpacing,
                        {
                            em: [typo.contentTextItalic],
                        },
                        { '& a': { color: colors.grayDark } },
                    ],
                },
            ]}
        >
            <MDText text={text} />
        </div>
    );
};

export const MarkdownTextAbout = ({ text }) => (
    <div
        css={[
            {
                p: [
                    typo.contentInfoAbout,
                    textContentSpacing,
                    { '& a': { color: colors.grayDark } },
                ],
            },
        ]}
    >
        <MDText text={text} />
    </div>
);

export const MarkdownTextImprint = ({ text }) => (
    <div
        css={[
            {
                p: [
                    typo.contentText,
                    textContentSpacing,
                    {
                        marginBottom: rem(32),
                        '& strong': [typo.contentTextBold],
                        '& a': [
                            {
                                color: colors.grayDark,
                            },
                        ],
                    },
                ],
            },
        ]}
    >
        <MDText text={text} />
    </div>
);
